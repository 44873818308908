var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : !_vm.isQueueing
          ? _c("div", { attrs: { id: "body-content-area" } }, [
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "flex-end",
                  },
                  attrs: { id: "buttongroup" },
                },
                [
                  _c("div", { staticClass: "field is-grouped" }, [
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button is-accent has-text-white",
                          attrs: {
                            type: "button",
                            disabled:
                              _vm.selectedInstitutions.length === 0 ||
                              !_vm.input.year ||
                              _vm.input.month === "",
                          },
                          on: { click: _vm.generate },
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-plus",
                            staticStyle: { "margin-right": "1em" },
                          }),
                          _vm._v(" Generate Questionnaires "),
                          _vm.isSaving
                            ? _c("i", {
                                staticClass: "fa fa-spinner fa-spin",
                                staticStyle: { "margin-left": ".75rem" },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button is-light",
                          on: {
                            click: function ($event) {
                              return _vm.$router.go(-1)
                            },
                          },
                        },
                        [_vm._v("Cancel")]
                      ),
                    ]),
                  ]),
                ]
              ),
              _c(
                "form",
                {
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.updateReport()
                    },
                  },
                },
                [
                  _c("div", { attrs: { id: "pagelayout" } }, [
                    _c("div", { staticClass: "columns" }, [
                      _c(
                        "div",
                        {
                          staticClass: "column",
                          staticStyle: { display: "flex", "flex-wrap": "wrap" },
                        },
                        [
                          _vm._m(0),
                          _c(
                            "div",
                            {
                              staticClass: "block",
                              staticStyle: { padding: ".5rem" },
                            },
                            [
                              _c("div", { staticStyle: { width: "100%" } }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "field",
                                    staticStyle: { width: "100%" },
                                  },
                                  [
                                    _vm._m(1),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "control select",
                                        staticStyle: { width: "100%" },
                                      },
                                      [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.input.year,
                                                expression: "input.year",
                                              },
                                            ],
                                            staticStyle: { width: "100%" },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.input,
                                                  "year",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          _vm._l(_vm.years, function (opt) {
                                            return _c(
                                              "option",
                                              {
                                                key: opt,
                                                domProps: { value: opt },
                                              },
                                              [_vm._v(_vm._s(opt))]
                                            )
                                          }),
                                          0
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "block",
                              staticStyle: { padding: ".5rem" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "block",
                                  attrs: {
                                    id: "institution-info-section-data",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "columns is-vcentered",
                                      staticStyle: {
                                        "align-items": "flex-start !important",
                                      },
                                    },
                                    [
                                      _c("div", { staticClass: "column" }, [
                                        _c("div", { staticClass: "field" }, [
                                          _vm._m(2),
                                          _c(
                                            "div",
                                            { staticClass: "control" },
                                            [
                                              _vm.institutions.length > 0
                                                ? _c("SearchableMultiSelect", {
                                                    staticStyle: {
                                                      width: "100%",
                                                      "min-width": "350px",
                                                    },
                                                    attrs: {
                                                      identifier:
                                                        "SearchableMultiSelect",
                                                      options: _vm.institutions,
                                                      selectedOptions:
                                                        _vm.selectedInstitutions,
                                                      stayOpen: true,
                                                    },
                                                    on: {
                                                      onchange:
                                                        _vm.onInstitutionsChange,
                                                    },
                                                  })
                                                : _c("div", [
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "has-text-gray pill",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " All institutions have reports generated for this period. "
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "column",
                                          staticStyle: {
                                            "min-height": "100% !important",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "field",
                                              staticStyle: {
                                                "min-height": "100% !important",
                                              },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass:
                                                    "label is-size-7",
                                                },
                                                [
                                                  _vm._v(
                                                    " Selected Institutions "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "control",
                                                  staticStyle: {
                                                    "min-height":
                                                      "100% !important",
                                                  },
                                                },
                                                [
                                                  _vm.selectedInstitutions
                                                    .length > 0
                                                    ? _c(
                                                        "SearchableMultiSelect",
                                                        {
                                                          staticStyle: {
                                                            width: "100%",
                                                            "min-width":
                                                              "350px",
                                                            height: "100%",
                                                          },
                                                          attrs: {
                                                            identifier:
                                                              "SearchableMultiSelect",
                                                            options:
                                                              _vm.selectedInstitutions,
                                                            stayOpen: true,
                                                            disabled: true,
                                                            "is-selected": true,
                                                          },
                                                          on: {
                                                            onchange:
                                                              _vm.onRemoveInstitution,
                                                          },
                                                        }
                                                      )
                                                    : _c("div", [
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "has-text-gray pill",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " No Institutions have been selected. "
                                                            ),
                                                          ]
                                                        ),
                                                      ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
            ])
          : _c(
              "div",
              [
                _c(
                  "center",
                  [
                    _c("h1", { staticClass: "title is-4" }, [
                      _vm._v(
                        "We're queuing the reports. This may take a moment."
                      ),
                    ]),
                    _c("spin-loader", { attrs: { isLarge: true } }),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass: "block",
        staticStyle: {
          padding: "0px 0px 7px 0px",
          "border-bottom": "solid 1px lightgray",
          width: "100%",
        },
      },
      [
        _c("span", { staticClass: "has-text-weight-bold" }, [
          _vm._v("Questionnaire Batch Details"),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Year "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", { staticClass: "label is-size-7" }, [
      _vm._v(" Institution "),
      _c(
        "span",
        {
          staticClass: "has-text-warning",
          staticStyle: { "padding-left": "3px" },
        },
        [_vm._v("*")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }